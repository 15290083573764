<template>
  <Header
    :change_section="change_section_selected"
    :section_selected="section_selected"
  />
  <router-view />
  <!-- <GastoEstado v-if="section_selected === 'gasto_estado'" />
  <EstadosAlertaActiva
    v-else-if="section_selected === 'estados_alerta_activa'"
  />
  <DescargaDatos v-else /> -->
  <Social />
  <Footer />
</template>

<script>
import { ref } from "vue";
import Header from "./components/Header.vue";
// import GastoEstado from "./views/GastoEstado.vue";
// import EstadosAlertaActiva from "./views/EstadosAlertaActiva.vue";
// import DescargaDatos from "./views/DescargaDatos.vue";
import Social from "./components/Social.vue";
import Footer from "./components/Footer.vue";

export default {
  setup() {
    const section_selected = ref("gasto_estado");
    function change_section_selected(section) {
      section_selected.value = section;
    }
    return {
      section_selected,
      change_section_selected,
    };
  },
  name: "App",
  components: {
    Header,
    // GastoEstado,
    // EstadosAlertaActiva,
    // DescargaDatos,
    Social,
    Footer,
  },
};
</script>

<style>
#app {
  font-family: "Titillium Web", Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--greyspdark);
}

h1 {
  font-size: 32px;
  font-weight: bold;
  color: #492973;
}

h2 {
  font-size: 16px;
  font-weight: bold;
  color: #000000;
}

h3 {
  font-size: 40px;
  font-weight: bold;
  color: #492973;
}
.body1 {
  font-size: 10px;
  font-weight: normal;
  color: #666666;
}

.body2 {
  font-size: 16px;
  font-weight: lighter;
  color: #666666;
}
.caption {
  font-size: 16px;
  font-weight: lighter;
}
.btn-font {
  font-size: 21px;
  font-weight: bold;
}
/* graf */
.node rect {
  cursor: move;
  fill-opacity: 0.9;
  shape-rendering: crispEdges;
}
.node text {
  pointer-events: none;
  text-shadow: 0 1px 0 #fff;
}
.link {
  fill: none;
  stroke: #000;
  stroke-opacity: 0.2;
}
.link:hover {
  stroke-opacity: 0.5;
}
div.tooltip {
  height: auto;
  font-family: "Titillium Web", "Helvetica Neue", sans-serif;
  position: absolute;
  text-align: center;
  padding: 4px;
  color: #888;
  font-weight: 500;
  background: white;
  border: 0;
  border-radius: 4px;
  pointer-events: none;
  -webkit-box-shadow: 7px 4px 13px 0px rgba(204, 204, 204, 1);
  -moz-box-shadow: 7px 4px 13px 0px rgba(204, 204, 204, 1);
  box-shadow: 7px 4px 13px 0px rgba(204, 204, 204, 1);
}
span.note,
.note {
  display: block;
  font-family: "Titillium Web", "Helvetica Neue", sans-serif;
  font-size: 12px;
  color: #696969;
  fill: #696969;
  margin-bottom: 0;
}
/* table gasto avgm in gasto estado  */
.containerTable {
  overflow: scroll;
  height: 500px;
}
.select {
  max-width: 100%;
  border-radius: 5px;
  border: none;
  box-shadow: 1px 1px 2px rgb(109, 100, 100, 0.5);
  cursor: pointer;
}
#gridGastoAvgm thead tr th,
#gridIncidenciaAvgm thead tr th {
  position: sticky;
  top: 0;
  background-color: #492973;
}
#gridGastoAvgm > thead h2,
#gridIncidenciaAvgm > thead h2 {
  color: #ffffff !important;
  font-size: 20px;
  margin: 0;
  text-align: center;
}
#gridGastoAvgm tbody,
#gridIncidenciaAvgm tbody {
  font-size: 12px;
}
#gridGastoAvgm tbody tr:hover,
#gridIncidenciaAvgm tbody tr:hover {
  background: #e6f7ff;
}
#gridGastoAvgm tbody td,
#gridIncidenciaAvgm tbody td {
  border-left: 2px solid #e8e8e8;
  text-align: center;
}
.tr_par {
  background-color: #ffffff;
}
.tr_inpar {
  background-color: #f4f4f4;
}
div.tooltip-sp {
  height: auto;
  font-family: "Titillium Web", "Helvetica Neue", sans-serif;
  position: absolute;
  text-align: center;
  padding: 4px;
  color: #888;
  font-weight: 500;
  background: white;
  border: 0;
  border-radius: 4px;
  pointer-events: none;
  -webkit-box-shadow: 7px 4px 13px 0px rgba(204, 204, 204, 1);
  -moz-box-shadow: 7px 4px 13px 0px rgba(204, 204, 204, 1);
  box-shadow: 7px 4px 13px 0px rgba(204, 204, 204, 1);
}
@media (min-width: 768px) {
  h1 {
    font-size: 46px;
  }
  h2 {
    font-size: 30px;
  }
  .body1 {
    font-size: 16px;
  }
  .body2 {
    font-size: 24px;
  }
}
@media (min-width: 992px) {
  h1 {
    font-size: 64px;
  }
}
</style>
