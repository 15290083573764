import { createRouter, createWebHashHistory } from 'vue-router';
import GastoEstado from './views/GastoEstado.vue';

const routes = [
    {
        path: '/',
        name: 'GastoEstado',
        component: GastoEstado,
    },
    {
        path: '/EstadosAlertaActiva',
        name: 'EstadosAlertaActiva',
        component: () => import(/* webpackChunkName: "about" */ './views/EstadosAlertaActiva.vue'),
    },
    {
        path: '/DescargaDatos',
        name: 'DescargaDatos',
        component: () => import(/* webpackChunkName: "about" */ './views/DescargaDatos.vue'),
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;