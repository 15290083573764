<template>
  <div class="container-fluid d-flex align-items-center header">
    <div class="container">
      <div class="row mb-4 mt-4 header__title">
        <h1 class="text-center" style="color: white">
          Gasto en la Alerta de Violencia de Género contra las Mujeres (AVGM)
        </h1>
      </div>
      <div class="row mb-4 header__section">
        <div @click="change_section('gasto_estado')" class="col-4">
          <router-link to="/">
            <h2
              :class="{
                header__sectionSelected: section_selected == 'gasto_estado',
              }"
            >
              Gasto por entidad
            </h2>
          </router-link>
        </div>
        <div @click="change_section('estados_alerta_activa')" class="col-4">
          <router-link to="/EstadosAlertaActiva">
            <h2
              :class="{
                header__sectionSelected:
                  section_selected == 'estados_alerta_activa',
              }"
            >
              Entidades con AVGM
            </h2>
          </router-link>
        </div>
        <div @click="change_section('descarga_datos')" class="col-4">
          <router-link to="/DescargaDatos">
            <h2
              :class="{
                header__sectionSelected: section_selected == 'descarga_datos',
              }"
            >
              Metodología y descarga de datos
            </h2>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {
    change_section: Function,
    section_selected: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  /* background-color: #492973; */
  background-color: #5e389b;
  color: #ffffff;
  height: 420px;
  margin-bottom: 100px;
}
.header__section > div {
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
}
.header__section div a {
  text-decoration: none;
}
.header__section {
  display: flex;
  align-items: center;
}
h2 {
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  margin: 0 !important;
  padding: 5px;
  align-self: flex-end;
  border-radius: 20px;
}
h3 {
  font-size: 16px;
}
.header__sectionSelected {
  background-color: #c7a4fc;
  box-shadow: 0 1px 2px 1px #cfc2e0;
}
@media (min-width: 768px) {
  h2 {
    font-size: 30px;
  }
  h3 {
    font-size: 32px;
  }
}
</style>